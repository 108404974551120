@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

$noteThemes: info, danger, success, warning;

.note {
  border: $border-width solid $gray-200;
  border-left-width: map-get($spacers, 1);
}

@each $theme in $noteThemes {
  .note-#{$theme} {
    border-left-color: shift-color(map-get($theme-colors, $theme), $alert-border-scale);
  }
}
