@import "~public/theme/scss/bootstrap/variables";
@import "~public/theme/scss/bootstrap/mixins";

.homepage-search-input {
  padding-block: calc(#{$input-padding-y-lg} + #{$input-border-width});

  @include media-breakpoint-up(md) {
    padding-block: calc(#{$input-padding-y-xl} + #{$input-border-width});
  }
}
