@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

.floatingLeftContainer {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.floatingRightContainer {
  float: right;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.floatingLeftContainer,
.floatingRightContainer {
  text-align: center;

  @include media-breakpoint-down(md) {
    float: none;
  }
}

.clearfix {
  @include clearfix();
}