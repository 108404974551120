@import "~shared/theme/scss/bootstrap/variables";
@import "~shared/theme/scss/bootstrap/mixins";

$jumbotron-padding: 2rem;

.jumbotron {
  padding: $jumbotron-padding ($jumbotron-padding / 2);

  @include media-breakpoint-up(sm) {
    padding: ($jumbotron-padding * 2) $jumbotron-padding;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  @include border-radius(0);
}

.jumbotron-gradient {
  &:not(.in-test) {
    min-height: 50vh;
  }

  &.in-test {
    min-height: 25rem;
  }

  &::after {
    background-color: rgba($primary-dark, .9);
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
